import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY } from "../../../models/user-model";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: boolean}>();

  constructor(
    private _renderer: Renderer2,
    private _amplitudeService: AmplitudeService
  ) { }

  ngOnInit(): void {
  }

  continue(event: any): void {
    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'quiz' , data: true});

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('intro3_welcome_page_3_continue_clicked');
  }
}
