<app-header [darkMode]="true"></app-header>

<section class="container text-center">
  <div class="img-wrap">
    <img src="../../../assets/images/img-main.png" alt="">
  </div>
  <h1>Find your transgender partner <br>today with Fiorry app</h1>
  <button  (click)="start($event)" class="button button-primary size-normal" id="btn-lets-begin-home">
    Let’s Begin
  </button>
  <p class="info">
    By continuing, you agree to our
    <a href="https://fiorry.co/terms-conditions" target="_blank" id="link-terms-conditions-home">Terms & Conditions</a> and <a href="https://fiorry.co/privacy-policy" target="_blank" id="link-privacy-policy-home">Privacy Policy</a>.
    Have a question? Reach our support team <a href="mailto:support@fiorry.co" id="link-to-support-home">here</a>.
  </p>
</section>

