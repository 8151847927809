<img src="../../../../assets/images/steps/step-6.png" alt="">
<h3 class="question">
  Which sex role <br/>do you prefer?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('Top', $event)"
         id="btn-ffm-group-sex">
  Top
</button>
<button  class="button button-secondary size-normal" (click)="continue('Bottom', $event)"
         id="btn-mmf-group-sex">
  Bottom
</button>
<button  class="button button-secondary size-normal" (click)="continue('Versatile', $event)"
         id="btn-not-interested-group-sex">
  Versatile
</button>

<p class="info">
  Your answers are not visible to others
</p>
