<img src="../../../../assets/images/steps/step-4.png" alt="">
<h3 class="question">
  How quickly are you<br/>open to sex?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('Right Away', $event)"
         id="btn-right-away-how-open">
  Right Away
</button>
<button  class="button button-secondary size-normal" (click)="continue('1-3 Dates', $event)"
         id="btn-1-3-dates-how-open">
  1-3 Dates
</button>
<button  class="button button-secondary size-normal" (click)="continue('Need Time', $event)"
         id="btn-need-time-how-open">
  Need Time
</button>

<p class="info">
  Your answers are not visible to others
</p>
