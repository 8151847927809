import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { StorageService } from "../../../services/storage.service";
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY } from "../../../models/user-model";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.component.html',
  styleUrls: ['./about-app.component.scss']
})
export class AboutAppComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: boolean}>();

  constructor(
    private _storageService: StorageService,
    private _renderer: Renderer2,
    private _amplitudeService: AmplitudeService
  ) { }

  ngOnInit(): void {
  }

  continue(event: any): void {
    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'aboutApp' , data: true});

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('intro3_welcome_page_2_continue_clicked');
  }
}
