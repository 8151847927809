import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-questions-progress',
  templateUrl: './questions-progress.component.html',
  styleUrls: ['./questions-progress.component.scss']
})
export class QuestionsProgressComponent implements OnInit {
  @Input() set id(id: number) {
    if (id !== undefined) {
      this.questionId = id;
      this.initProgressBar();
    }
  }

  questionId!: number;
  progressBar: {id: number, active: boolean}[] = [
    {id: 1, active: false},
    {id: 2, active: false},
    {id: 3, active: false},
    {id: 4, active: false},
    {id: 5, active: false},
    {id: 6, active: false},
    {id: 7, active: false},
    {id: 8, active: false}
  ];

  constructor() { }

  ngOnInit(): void {
  }

  initProgressBar(): void {
    this.progressBar.map(item => {
      item.active = (item.id <= this.questionId);
    });
  }
}
