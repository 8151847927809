import { Component, Input, OnInit } from '@angular/core';
import { QuestionsModel } from "../../models/questions-model";
import { StorageService } from "../../services/storage.service";
import { TIME_DELAY, UserModel } from "../../models/user-model";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTES_PATH } from "../../app.component";
import {AmplitudeService} from "../../services/amplitude.service";

@Component({
  selector: 'app-questions-flow',
  templateUrl: './questions-flow.component.html',
  styleUrls: ['./questions-flow.component.scss']
})
export class QuestionsFlowComponent implements OnInit {
  @Input() set initFire(val: boolean) {
    if (val !== undefined && val) this.ngOnInit(true);
  }
  userState!: UserModel | null;
  activeQuestionId!: number;

  constructor(
    private _storageService: StorageService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _amplitudeService: AmplitudeService
  ) {
    if (this._storageService.userState) this.userState = this._storageService.userState;
    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(initFire?: boolean): void {
    if (initFire) {
      if (!this.userState) {
        this.initUserState();
      } else {
        this.setActiveQuestionId();
      }
    }
  }

  initUserState(): void {
    this.userState = new UserModel();

    this.activeQuestionId = 1; // set default question ID
    this.setUrlParam(this.activeQuestionId);
    this._storageService.setUserState(this.userState);
  }

  setActiveQuestionId(): void {
    if (this.userState) {
      const questionsKeys = (Object.keys(this.userState.questions) as Array<keyof QuestionsModel>)

      for (let questionName of questionsKeys) {
        const isLastQuestion: boolean = this.userState.questions[questionName].id === 8;
        const hasData: boolean = !!this.userState.questions[questionName].data;

        if (this.userState && !hasData) {
          setTimeout(() => {
            if (this.userState) {
              this.activeQuestionId = this.userState.questions[questionName].id;
              this.setUrlParam(this.activeQuestionId);
            }
          }, TIME_DELAY);
          return;
        } else if (isLastQuestion && hasData) {

          const queryParams = this._activatedRoute.snapshot.queryParams;

          if (queryParams['force']) {
            this.activeQuestionId = 8;
            this.clearQueryParams();
            this.setUrlParam(this.activeQuestionId);
          } else {
            setTimeout(() => {
              this._router.navigate([ROUTES_PATH.EMAIL]);
            }, TIME_DELAY);
          }

        }
      }
    }
  }

  clearQueryParams(): void {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {},
      replaceUrl: true
    });
  }

  setUrlParam(questionId: number): void {
    this._router.navigate([ROUTES_PATH.QUESTIONS_FLOW, questionId]);
  }

  continue(event: {propName: keyof QuestionsModel, data: any}): void {
    if (!this.userState || !this.userState.questions) {
      console.error('QuestionsFlowComponent#continue error with `userState` object');
      return;
    }
    this.userState.questions[event.propName].data = event.data;

    this._storageService.setUserState(this.userState);

    this.setActiveQuestionId();
  }

  goBack(): void {
    const newActiveQuestionId = this.activeQuestionId - 1;

    this.cleanOldAnswer(this.activeQuestionId);

    if (newActiveQuestionId === 0) {
      this._amplitudeService.trackEvent('intro3_back_button_clicked', {source_page: ''});
      this._router.navigate(['']);
    } else {
      this._amplitudeService.trackEvent('intro3_back_button_clicked', {source_page: 'questions-flow-' + newActiveQuestionId});
      this.activeQuestionId = newActiveQuestionId;
      this.setUrlParam(this.activeQuestionId);
    }
  }

  cleanOldAnswer(oldId: number): void {
    if (this.userState?.questions) {
      (Object.keys(this.userState.questions) as Array<keyof QuestionsModel>).map(key => {
        if (this.userState && this.userState.questions[key].id >= oldId ) {
          delete this.userState?.questions[key].data
        }
      });

      this._storageService.setUserState(this.userState);
    }
  }
}
