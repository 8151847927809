import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from "@angular/router";
import { StorageService } from "./storage.service";
import { ROUTES_PATH } from "../app.component";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private _isLogged!: boolean;

  constructor(
    private _storageService: StorageService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const url: UrlSegment = route.url[0];

    if(!url) return true;

    switch (url.path) {
      case ROUTES_PATH.EMAIL:
        if (this.canNavigate(url.path)) {
          return true;
        } else {
          this._router.navigate([ROUTES_PATH.QUESTIONS_FLOW]);
          return false;
        }
      case ROUTES_PATH.FIND_IDEAL:
        if (this.canNavigate(url.path)) {
          return true;
        } else {
          this._router.navigate([ROUTES_PATH.EMAIL]);
          return false;
        }
      case ROUTES_PATH.CHECKOUT:
        if (this.canNavigate(url.path)) {
          return true;
        } else {
          this._router.navigate([ROUTES_PATH.FIND_IDEAL]);
          return false;
        }
      default:
        console.log('canNavigate#default switch');
        return true;
    }

    return true;
  }

  // set logged(val: boolean) {
  //   this._isLogged = val;
  // }

  private canNavigate(path: string): boolean {
    if (path === ROUTES_PATH.EMAIL) {
      return this._storageService.userState?.questions?.aboutApp?.data || false;
    }

    if (path === ROUTES_PATH.FIND_IDEAL) {
      return !!this._storageService.userState?.price;
    }

    if (path === ROUTES_PATH.CHECKOUT) {
      return this._storageService.userState?.findIdeal || false;
    }

    console.error('canNavigate# - no path matches for redirect');
    return true;
  }
}
