import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { ROUTES_PATH } from "../../app.component";
import { CountdownComponent, CountdownConfig } from "ngx-countdown";
import { UserService } from "../../services/user.service";
import { FormBuilder } from "@angular/forms";
import { StripeService, STRIPE_PUBLISHABLE_KEY } from "ngx-stripe";
import { HttpClient } from "@angular/common/http";
import { loadStripe, Stripe, StripeCardElement, PaymentRequest } from "@stripe/stripe-js";
import { AmplitudeService } from "../../services/amplitude.service";
import { UserModel } from "../../models/user-model";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @Input() set initFire(val: boolean) {
    // if (val !== undefined && val) this.ngOnInit(true);
  }
  @ViewChild('cd', { static: false }) private countdown!: CountdownComponent;

  countDownConfig!: CountdownConfig;
  userState!: UserModel;
  userOldPrice!: string;
  loading: boolean = false;
  mobileOperatingSystem!: 'ios' | 'android' | 'windows' | 'unknown';

  showCreditCardForm: boolean = false;

  stripe: Stripe | null = null;
  elements: any;
  card: StripeCardElement | null = null;
  paymentRequest: PaymentRequest | null = null;

  productIds = {
    yearly: 'prod_Qip29zcMp1SaMJ',
    monthly: 'prod_Qip1KxUr9519yF',
    weekly: 'prod_Qip11cZxpZqCWg'
  };

  selectedProduct = this.productIds.monthly;

  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _fb: FormBuilder,
    private _stripeService: StripeService,
    private _http: HttpClient,
    @Inject(STRIPE_PUBLISHABLE_KEY) private key: string,
    private _amplitudeService: AmplitudeService
  ) {
    if (this._storageService.userState) {
      this.userState = this._storageService.userState;
      this.calculateOldPrice(this.userState.price);
    }

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) {
        this.userState = this._storageService.userState;
        this.calculateOldPrice(this.userState.price);
      }
    });

    this.setCountDownConfig(5000);
  }

  async ngOnInit(initFire?: boolean): Promise<void> {
    console.log('ngOnInit called with initFire:', initFire);
    if (initFire) {
      let timeSeconds: number | null = null;
      const timeQueryParams = this._activatedRoute.snapshot.queryParams['time'];
      if (timeQueryParams) {
        this.clearQueryParams();
        timeSeconds = parseInt(timeQueryParams, 10);
      }

      this.setCountDownConfig(timeSeconds);
      this.mobileOperatingSystem = this.getMobileOperatingSystem();
    }

    await this.initStripe();
  }

  async initStripe(): Promise<void> {
    console.log('Initializing Stripe...');
    try {
      if (!this.stripe) this.stripe = await loadStripe(this.key);

      if (!this.stripe) {
        throw new Error('Stripe failed to initialize');
      }
      console.log('Stripe initialized successfully.');
      this.elements = this.stripe.elements();

      if (this.elements) {
        this.setupCardElement();
        await this.setupPaymentRequestButton();
      } else {
        throw new Error('Stripe Elements initialization failed');
      }
    } catch (error) {
      console.error('Stripe initialization failed:', error);
      this.displayError('Failed to initialize payment gateway. Please try again.');
    }
  }

  setupCardElement(): void {
    if (!this.elements) return;

    const style = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF'
        }
      },
      invalid: {
        color: '#E25950'
      }
    };

    // Card number element
    const cardNumber = this.elements.create('cardNumber', {
      style: style,
      showIcon: true
    });
    cardNumber.mount('#card-number');

    // Expiry date element
    const cardExpiry = this.elements.create('cardExpiry', { style });
    cardExpiry.mount('#card-expiry');

    // CVC element
    const cardCvc = this.elements.create('cardCvc', { style });
    cardCvc.mount('#card-cvc');

    // Ensure the form is not submitted until the card element is ready
    cardNumber.on('ready', () => {
      console.log('Card number element is ready');
      this.card = cardNumber;  // Assign the card element to the class property after it's ready
    });

    // Similarly, ensure the expiry and CVC elements are ready if necessary
    cardExpiry.on('ready', () => {
      console.log('Card expiry element is ready');
    });

    cardCvc.on('ready', () => {
      console.log('Card CVC element is ready');
    });
  }



  async setupPaymentRequestButton() {
    if (!this.stripe || !this.elements || !this.userState || !this.userState.price) return;

    const amount: number = Number(this.userState.price.replace('$', '').replace('.', ''));

    // Create a Stripe payment request
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total for subscription',
        amount: amount
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    // Check the availability of the Payment Request API for Apple Pay / Google Pay
    const result = await this.paymentRequest.canMakePayment();

    if (result) {
      // Show buttons based on the available payment methods
      if (result['applePay']) {
        // If Apple Pay is available, show the Apple Pay button
        document.getElementById('btn-apple-pay-checkout')!.style.display = 'block';
        document.getElementById('btn-apple-pay-checkout')!.addEventListener('click', () => {
          this.paymentRequest?.show();
        });
      }

      if (result['googlePay']) {
        // If Google Pay is available, show the Google Pay button
        document.getElementById('btn-google-pay-checkout')!.style.display = 'block';
        document.getElementById('btn-google-pay-checkout')!.addEventListener('click', () => {
          this.paymentRequest?.show();
        });
      }
    } else {
      // Payment methods are not available: hide all buttons.
      document.getElementById('btn-apple-pay-checkout')!.style.display = 'none';
      document.getElementById('btn-google-pay-checkout')!.style.display = 'none';
      console.log('Google Pay / Apple Pay is not available');
    }

    // Handle the payment method response from Stripe
    this.paymentRequest.on('paymentmethod', async (ev) => {
      try {
        const subscriptionResult = await this.createSubscription(ev.paymentMethod.id);
        if (subscriptionResult.status === 'success') {
          ev.complete('success');
          this.handleSuccess(subscriptionResult.voucher);
        } else {
          ev.complete('fail');
          this.displayError(subscriptionResult.message || 'Subscription failed');
        }
      } catch (error) {
        ev.complete('fail');
        this.displayError('An error occurred while processing your payment');
      }
    });
  }


  async createSubscription(paymentMethodId: string): Promise<any> {
    try {
      this.setLoading(true);
      const response = await fetch('https://api.paradiz.app/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          product_id: this.selectedProduct,
          project_version: 'varA-fiorry',
          email: this.userState.email
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Subscription response:', data);

      if (data.status === 'success') {
        return { status: 'success', voucher: data.voucher };
      } else {
        return { status: 'error', message: data.message || 'Subscription failed' };
      }
    } catch (error) {
      console.error('Subscription creation failed:', error);
      return { status: 'error', message: 'Failed to set up subscription.' };
    } finally {
      this.setLoading(false);
    }
  }

  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
    if (!this.card || !this.stripe) {
      this.displayError('Payment form is not ready. Please try again.');
      return;
    }

    try {
      this.setLoading(true);
      console.log('Creating payment method...');
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card
      });

      if (error || !paymentMethod) {
        throw new Error(error?.message || 'Failed to create payment method');
      }

      console.log('Payment method created:', paymentMethod.id);
      const subscriptionResult = await this.createSubscription(paymentMethod.id);

      if (subscriptionResult.status === 'success') {
        this.handleSuccess(subscriptionResult.voucher);
      } else {
        this.displayError(subscriptionResult.message);
      }
    } catch (error) {
      console.error('Payment submission failed:', error);
      this.displayError((error as Error).message || 'An error occurred while processing your payment');
    } finally {
      this.setLoading(false);
    }
  }

  handleSuccess(voucher: string): void {
    console.log('Handling success, voucher:', voucher);
    document.querySelector('.example4')!.classList.add('submitted');

    this.updateUserData();

    setTimeout(() => {
      this._router.navigate([ROUTES_PATH.CHECKOUT_SUCCESS], { queryParams: { voucher: voucher } });
    }, 2000);
  }

  displayError(errorMsg: string): void {
    console.error('Display error:', errorMsg);
    const errorElement = document.querySelector('.error');
    const errorMessage = errorElement?.querySelector('.message');
    if (errorElement && errorMessage) {
      errorElement.classList.add('visible');
      errorMessage.textContent = errorMsg;
    } else {
      console.error('Error elements not found in the DOM');
    }

    // TODO temporary disabled
    // setTimeout(() => {
    //   this._router.navigate([ROUTES_PATH.CHECKOUT_ERROR]);
    // }, 4000);
  }

  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
    const button = document.querySelector('button');
    if (button) {
      button.disabled = isLoading;
    }
  }

  getMobileOperatingSystem(): 'ios' | 'android' | 'windows' | 'unknown' {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/windows phone/i.test(userAgent)) {
      return "windows";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "ios";
    }
    return "unknown";
  }

  updateUserData(): void {
    if(this.loading) return;
    this.loading = true;

    const uuid = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      donation_amount: this.userState.price // payment_method: 'STRIPE'
    }).subscribe();
  }

  private setCountDownConfig(seconds: number | null): void {
    const expiresInSeconds = seconds || 900;
    const format = 'mm:ss';
    this.countDownConfig = {
      leftTime: expiresInSeconds,
      format: format,
      prettyText: (text) => {
        return text.split(':').map((v, index) => {
          const single = v.split('');
          let item = `<span class="count-down-item">${single[0]}</span><span class="count-down-item">${single[1]}</span>`;
          if (index === 1) item = `<span class="count-down-item-separator">:</span>${item}`;
          return item;
        }).join('');
      }
    };
  }

  clearQueryParams(): void {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {},
      replaceUrl: true
    });
  }

  calculateOldPrice(price: string | undefined): void {
    if (!price) return;
    const priceArray: string[] = price.split('$');
    const parsedPrice: number = Number(priceArray[1]);
    this.userOldPrice = '$' + (parsedPrice * 2).toFixed(2);
  }

  setAmplitudeOnBtnClickEvent(): void {
    const eventOptions: Record<string, any> = {
      offer_type: 'none',
      time_to_convert: this.countdown.left / 1000,
    };
    this._amplitudeService.trackEvent('intro3_payment_debit_credit_clicked', eventOptions);
  }
}
