import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { QuestionsProgressComponent } from "./components/questions-progress/questions-progress.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";
import { CountdownModule } from "ngx-countdown";

const COMPONENTS = [
  HeaderComponent,
  QuestionsProgressComponent,
  UserInfoComponent
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CountdownModule
];

@NgModule({
  imports: [
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<RouterModule> {
    return <ModuleWithProviders<RouterModule>>{
      ngModule: SharedModule,
      providers: []
    };
  }
}
