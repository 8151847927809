<img src="../../../../assets/images/steps/step-5.png" alt="">
<h3 class="question">
  Do you like <br/>anal sex?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('Yes', $event)" id="btn-yes-anal-sex">
  Yes
</button>
<button  class="button button-secondary size-normal" (click)="continue('No', $event)" id="btn-no-anal-sex">
  No
</button>
<button  class="button button-secondary size-normal" (click)="continue('Maybe', $event)" id="btn-maybe-anal-sex">
  Maybe
</button>

<p class="info">
  Your answers are not visible to others
</p>
