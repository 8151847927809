<img src="../../../../assets/images/steps/step-1.png" alt="">
<h3 class="question">
  Our app lists all naughty people nearby for you to message.
</h3>
<p class="info">
  Simple concept, focused on real matches, not profit. Created by a small team. Plus, a killer feature waiting for you...
</p>
<button  class="button button-primary size-normal" (click)="continue($event)" id="btn-continue-about-app">
  Continue
</button>
