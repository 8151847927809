<img src="../../../../assets/images/steps/step-3.png" alt="">
<h3 class="question">
  What’s your<br/>dating goal?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('Sex & Relationship', $event)"
         id="btn-sex-relationship-dating-goal">
  Sex & Relationship
</button>
<button  class="button button-secondary size-normal" (click)="continue('Just Sex', $event)"
         id="btn-just-sex-dating-goal">
  Just Sex
</button>
<button  class="button button-secondary size-normal" (click)="continue('Friends with Benefits', $event)"
         id="btn-friends-dating-goal">
  Friends with Benefits
</button>

<p class="info">
  Your answers are not visible to others
</p>
