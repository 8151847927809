<img src="../../../../assets/images/steps/step-7.png" alt="">
<h3 class="question">
  Does the idea of sex in public<br/>places excite you?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('Yes', $event)"
         id="btn-yes-public-sex">
  Yes
</button>
<button  class="button button-secondary size-normal" (click)="continue('No', $event)"
         id="btn-no-public-sex">
  No
</button>
<button  class="button button-secondary size-normal" (click)="continue('Maybe', $event)"
         id="btn-maybe-public-sex">
  Maybe
</button>

<p class="info">
  Your answers are not visible to others
</p>
